body {
	text-align: center;
	background-color: black;
}

.nav-item {
	margin: 0 .5em;
	text-decoration: none;
}

.nav-link:hover {
	opacity: .7;
}

.form-inline {
	text-align: center;
}

ul {
	padding: 0;
	list-style: none;
}

li {
	font-size: 1.5rem;
	line-height: 2rem;
}

.blogger-pic {
	background-color: #777;
	border-radius: 20px;
	border: 1px #777 solid;
}

.youtube-pic {
	background-color: #777;
	/* border-radius: 20px; */
	border: 1px #777 solid;
}

.kiss-channel {
	border: solid 4px #777;
	border-radius: 5px;
	width: 650px;
	height: 480px;
}

.header {
	margin: 5em 0 40px 0;
	width: 100%;
}

h3 {
	background-color: #353A40;
	border: solid 3px #353A40;
	border-radius: 5px;
	color: #ffffff;
}

a {
	color: black;
}

a:hover {
	opacity: .9;
	text-decoration: none;
	color: #353A40;
}

.kiss-item:hover {
	background-color: #444444;
}

.kiss-item {
	font-size: .75em;
	width: 80%;
}

.searchDropdown {
	background-color: #fff;
	border-radius: 0 0 5px 5px;
	font-size: .9em;
	position: fixed;
	margin-top: 2em;
	text-align: center;
	box-shadow: 2px 2px 8px #777;
}

.main-blurb, .featured-items, .contactform, .adminform, .authform {
	margin: 10px 0;
	width: 100%;
	background-color: #ffffff;
	padding: 20px;
	border: solid 3px #fff;
	border-radius: 5px;
	font-size: 1.2em;
}

.disclaimer {
	color: darkred;
	font-weight: bold;
}

.grid-container {
	display: inline-grid;
	grid-template-columns: 33% 33% 33%;
}

.grid-item {
	padding: .5em;
	border: solid 5px #fff;
	border-radius: 5px;
	margin: 1em;
	box-shadow: 2px 2px 8px #777;
}

.uploaded {
	margin: 1% 2%;
}

/* .featured-image {
	  border: solid 2px darkgray;
	  border-radius: 20px;
  } */

h4 {
	font-weight: bold;
	font-size: 1.1em;
}

td {
	width: 45%;
	padding: 5px;
}

tr {
	height: 10px;
}

footer {
	text-align: center;
	color: #fff;
}

.light {
	color: #fff;
	font-size: 80%;
}

textarea {
	border-radius: 20px;
	border: 1px solid #f2f2f2;
}

.form-control {
	width: 100%;
}

input {
	border: 1px solid #f2f2f2;
}

.photo-upload {
	padding: 3em;
	border: dashed darkgray 3px;
	cursor: pointer;
}

.select {
	width: 10%;
	margin: 0 -.1em 0 1em;
}

.options {
	margin: 0 .2em;
}

.checkbox-div {
	display: grid;
	grid-template-columns: auto auto auto;
	text-align: left;
}

.checkbox {
	margin: 0.5em;
	display: flex;
	flex-direction: row;
}

.checked {
	margin: .5em;
}

.text-input {
	border: 1px solid #f2f2f2;
	background-color: #d8d8d8;
	width: 100%;
	border-radius: 10px;
	margin-top: .1em;
}

.text-input:focus {
	border: 2px solid #3b03d4;
}

.btn {
	background-color: #d8d8d8;
	border: solid 4px #f0f0;
	margin: .1em 0;
}

.white-text {
	color: #fff;
}

.error-msg {
	text-align: center;
	font-weight: bold;
	font-size: 1.1em;
}

.main-search {
	color: #000;
}

.mainImageAdmin {
	border: 5px solid darkblue;
}

.edit-image-container {
	display: flex;
	flex-direction: row;
	overflow-y: scroll;
}

.uploaded-display {
	margin: .2em;
	cursor: pointer;
}

.editModal {
	position: fixed;
	z-index: 100000010;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 5px 5px 20px;
	width: 90%;
	max-height: 90%;
	overflow: scroll;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.edit-item {
	cursor: pointer;
}

.edit-item:hover {
	opacity: .7;
}

.modal-overlay {
	z-index: 100000000;
	background-color: #353A40;
	opacity: .8;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* individual item view */

.item-view-thumbnail {
	max-width: 80px;
	margin: .5em;
	cursor: pointer;
	box-shadow: 1px 2px 2px #999;
	border-radius: 5px;
}

.large-view-modal {
	position: fixed;
	z-index: 100000010;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 5px 5px 20px;
	width: 600px;
	max-height: 80%;
	overflow: scroll;
	top: 50%;
	left: 40%;
	transform: translate(-50%, -50%);
}

.large-view-image {
	width: 600px;
}

.item-view-body {
	margin-top: 1em;
	min-height: 20em;
}

/* Search list view */

.navlink-wrapper {
	width: 100%;
	display: flex;
	border: 2px solid #fff;
	border-radius: 5px;
	margin: .5em;
	padding: .5em;
	flex-wrap: wrap;
	box-shadow: 2px 2px 8px #777;
}

.list-view-header {
	align-self: center;
	width: 100%;
}

.list-view-container {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	justify-content: space-around;
	flex-wrap: wrap;
}

.list-view-thumbnail {
	max-width: 150px;
	max-height: 150px;
	margin: .5em auto;
}

.list-view-body {
	padding: .5em;
}

.list-view-description {
	flex-grow: 1.1;
}

.player-wrapper {
	position: relative;
	padding-top: 56.25%/* Player ratio: 100 / (1280 / 720) */
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}

.addNewCatBtn {
	font-size: 1.1em;
	cursor: pointer;
	margin: 0 .3em;
	border: 1px gray solid;
}

@media only screen and (max-width: 800px) {
	.grid-container, .checkbox-div {
		grid-template-columns: auto auto;
	}
	.large-view-modal {
		left: 50%;
		width: 700px;
	}
	kiss-channel {
		max-width: 500px;
		max-height: 380px;
	}
}

@media only screen and (max-width: 500px) {
	.grid-container, .checkbox-div {
		grid-template-columns: auto;
	}
	.large-view-modal {
		position: fixed;
		z-index: 100000010;
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 5px 5px 20px;
		width: 300px;
		max-height: 80%;
		overflow: scroll;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.large-view-image {
		width: 300px;
	}
	iframe {
		max-width: 350px;
		max-height: 270px;
	}
}